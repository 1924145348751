// Set file variable
$filename: 'scss/custom/_custom.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

/**
 * Listing Promotions
 */
.listing-promotions a:hover {
  .experation-date .chip {
    background-color: $white;
  }
}

// Set file variable
$filename: 'scss/custom/_custom-alt.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

body {
  background: $shade url('/fs-shared/dist/img/pattern.png') repeat center top;
}

/*==============================================================================
  @Nav - #{$filename}
==============================================================================*/

.nav {
  border-bottom: 1px solid rgba($black, 0.1);
}
.nav-menu > li > a {
  color: $color;

  &:hover {
    color: $blue;
  }
}

/*==============================================================================
  @Logo - #{$filename}
==============================================================================*/

.logo {
  color: $color;

  &:visited {
    color: $color;
  }
  &:hover,
  &:focus {
    color: $color;
  }
}
.logo .vs-logo-sec {
  background-image: url('/fs-shared/img/vs-logo-sec-dark.svg');
}

/**
 * Logo Parts
 */
.logo .member {
  color: $color-light;
}
.logo .sep {
  background: rgba($black, 0.1);
}

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  background: $bg-nav;
  border-bottom: 1px solid rgba($black, 0.1);

  .button {
    color: $color;

    &:visited {
      color: $color;
    }
    &:hover,
    &:focus {
      color: $accent;
    }
  }
}

@include media-min('medium') {
  .action-menu {
    border-bottom: 0 none;
  }
  .action-menu .button:not(.text) {
    color: $accent;
    border: 1px solid $accent;
    &:visited {
      color: $accent;
    }
    &:hover,
    &:focus {
      color: $color;
      border-color: $color;
    }
  }
}

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button.primary {
  @include add-button-color((
    'color' : $white,
    'background' : $accent,
  ));
}

/*==============================================================================
  @Widget - #{$filename}
==============================================================================*/

.widget {
  background: $shade url('/fs-shared/dist/img/pattern.png') repeat center top;

  .button {
    @extend .button.primary;
  }
}

.widget-locations {
  background: none;
}

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

.featured-action {
  background: $shade url('/fs-shared/dist/img/pattern.png') repeat center top;
}

.featured-action a {
  color: $color-light;

  h2 {
    color: $color;
  }
  .icon {
    color: $accent;
  }

  &:visited {
    color: $color-light;
  }
  &:hover,
  &:focus {
    color: $color-light;
    background: rgba($white, 0.5);
  }
}

// Set file variable
$filename: 'scss/custom/_header-logo.scss';

/*==============================================================================
  @Logo - #{$filename}
==============================================================================*/

.logo {
  display: inline-block;
  margin: 0 0 1.5em;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  border: none;

  > span {
    display: block;
  }
  &:visited {
    color: $white;
  }
  &:hover {
    color: $white;
    text-decoration: none;
  }
}
@include media-min('medium') {
  .logo {
    text-align: left;
    margin: 0 2em 0 0;
  }
  .logo-region {
    display: flex;
    align-items: center;
  }
}

/**
 * Logo Message
 */

.logo-message {
  margin-bottom: 1em;
}

@include media-min('medium') {
  .logo-message {
    margin-bottom: 0;
  }
}

/**
 * Logo Parts
 */
.logo .name {
  font-size: px-to-em(22px);
  line-height: 1.3em;
  font-weight: bold;
}
.logo .member {
  font-size: px-to-em(14px);
  color: rgba($white, 0.5);
}
.logo .region {
  display: block;
  font-size: px-to-em(18px);
  font-weight: bold;
}
.logo .sep {
  display: block;
  width: 40px;
  height: 1px;
  margin: 0.5em auto;
  background: rgba($white, 0.1);
}

@include media-min('medium') {
  .logo .region {
    display: inline-block;
  }
  .logo .sep {
    display: inline-block;
    width: 1px;
    height: 40px;
    margin: 0 1em;
    transform: rotate(15deg);
  }
}

/**
 * Network Branding
 */
.logo .network-logo,
.logo .network-logo-sdc {
  display: inline-block;
  vertical-align: top;
  @include text-hide();
}
.logo .network-logo {
  width: 152px;
  height: 21px;
  margin: 0 5px;
  background: url('/fs-shared/dist/img/ss-logo-green.svg') no-repeat center center;
  background-size: 152px 21px;
}
.logo .network-logo-sdc {
  flex: 1 0 auto;
  width: 231px;
  height: 46px;
  margin-top: 0;
  background: url('/fs-shared/dist/img/ss-logo-sdc-light.svg') no-repeat center center;
  background-size: 231px 46px;
}
@include media-min('medium') {
  .logo .network-logo-sdc {
    margin-top: 0;
  }
}

/**
 * Custom Logo
 */

.logo_custom {
  img {
    margin: 0;
  }
}

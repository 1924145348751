// Set file variable
$filename: 'scss/custom/_footer.scss';

/*==============================================================================
  @Footer - #{$filename}
==============================================================================*/

.footer {
  padding: 1em 0;
  font-size: px-to-em(14px);
  line-height: 1.5em;
  text-align: center;
}
@include media-min('medium') {
  .footer {
    text-align: left;
    padding: 2em 0;
  }
}

.footer {
  h3 {
    font-size: px-to-em(18px, 14px);
    font-weight: font-weight('semi-bold');
  }
  a {
    color: $color;

    &:hover,
    &:focus {
      color: $accent;
      border-color: rgba($accent, 0.5);
    }
  }
  .button {
    @include add-icon-button('left');
  }
}

/**
 * Footer Lists
 */
.footer-list {
  list-style: none;
  margin: 1em -1em;

  li {
    margin: 1px 0;
  }
  li a {
    display: block;
    padding: 0.5em 1em;
    border: none;
    border-radius: $border-radius;

    &:hover {
      background: rgba($white, 0.5);
      color: $color;
      text-decoration: none;
    }
  }

  .title,
  .timestamp {
    display: block;
  }
  .timestamp {
    color: $color-light;
  }
}

@include media-min('medium') {
  .footer-list {
    margin: 1em 1em 1em -1em;
  }
}

/**
 * Footer Social Links
 */
.footer-social-links {
  justify-content: center;
}
@include media-min('medium') {
  .footer-social-links {
    justify-content: flex-start;
  }
}

/**
 * Footer Buttons
 */
.footer .button.text {
  background: none;

  &:hover {
    background: rgba($white, 0.5);
    color: $color;
  }
}

/**
 * Footer Copyright
 */
.footer-copyright {
  text-align: center;
  color: $color-light;

  .sep {
    margin: 0 0.5em;
    color: $color-light;
  }

  a {
    color: $color-light;

    &:visited {
      color: $color-light;
    }
    &:hover,
    &:focus {
      color: $accent;
    }
  }
}

/**
 * Logo
 */
.footer-logo {
  position: relative;
  overflow: hidden;
  margin: 2em 0;

  .network-logo-sdc {
    box-sizing: content-box;
    position: relative;
    z-index: 10;
    display: block;
    width: 180px;
    height: 47px;
    margin: 0 auto;
    padding: 0 20px;
    background: $shade url('/fs-shared/dist/img/ss-logo-sdc-dark.svg') no-repeat center center;
    background-size: 180px 47px;
    border: none;
    @include text-hide();
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 0;
    height: 4px;
    width: 100%;
    margin-top: -2px;
    border-top: 1px solid rgba($black, 0.1);
    border-bottom: 1px solid rgba($black, 0.1);
  }
}

////////////////////////////////////////////////////////////////////////////////
// @Override Variables
////////////////////////////////////////////////////////////////////////////////

$accent: #01AA4F;
$accent-brand: #01AA4F;
$secondary: #3B454D;
$shade: #EFEFEF;

$bg-body: $shade;
$bg-nav: $white;
$bg-header: $white;
$bg-banner: $white;
$bg-banner-full: #404040;
$bg-main: $white;
$bg-widget: $shade;
$bg-footer: $shade;

$base-font-family: "Roboto", "Helvetica Neue", "HelveticaNeue", helvetica, arial, sans-serif;
$font-weight-heading: normal;

$color-link: $accent;
$color-link-hover: darken($accent, 5%);

$color-link-heading: $accent;
$color-link-heading-hover: darken($accent, 5%);

$bg-color: $shade;

////////////////////////////////////////////////////////////////////////////////
// @Widget Variables
////////////////////////////////////////////////////////////////////////////////

$widget-background: $shade;
$widget-border: null;

////////////////////////////////////////////////////////////////////////////////
// @Overrides Map
////////////////////////////////////////////////////////////////////////////////

$override: (

  'grid' : (
    'container-max-width' : 65rem,
  ),

  // Element Overrides
  'buttons' : (
    'classes' : false,
    'border' : none,
    'box-shadow' : none,
    'hover' : (
      'box-shadow' : none,
    ),
    'active' : (
      'box-shadow' : none,
    ),
  ),

  'icons' : (
    'font-size' : px-to-rem(28px),
    'stroke-width' : 0,
    'fill' : currentColor,
  ),

  // Block Overrides
  'button-groups' : (
    'display' : 'block',
    'orientation' : 'vert',
    'spacing' : 0.5em,
    'border-radius' : null
  ),

  'notices' : (
    'border' : 1px solid rgba($black, 0.15),
  ),

  'dropdowns' : (
    'font-size' : px-to-rem(14px),
    'border-inner' : null,
    'transition' : none,
  ),

  'offcanvas' : (
    'background-wrap' : transparent,
    'background-content' : transparent,
  ),

);

////////////////////////////////////////////////////////////////////////////////
// @Settings Overrides
////////////////////////////////////////////////////////////////////////////////

// There are three maps that get defined before this override file is loaded
// To extend them with the override map we need to extend them here
// ---
// Extend default component map with overrides if they exist
@if variable-exists(override) {
  @if (map-has-key($override, 'grid') == true) {
    $grid: map-extend($grid, map-get($override, 'grid'), true);
  }
  @if (map-has-key($override, 'mini-grid') == true) {
    $mini-grid: map-extend($mini-grid, map-get($override, 'mini-grid'), true);
  }
  @if (map-has-key($override, 'breakpoints') == true) {
    $breakpoints: map-extend($breakpoints, map-get($override, 'breakpoints'), true);
  }
}
